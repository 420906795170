
import { Vue, Component, Prop } from "vue-property-decorator";
import VisitDropdown from "@/components/visits/VisitDropdown.vue";
import { get } from "@/api";
import { Session } from "@/interfaces/therapy";
import { displaySpanRange } from "@/views/therapistCalendar/helpers";
import { sessionDate } from "./helpers";
import i18n from "@/i18n";
const pageKey = "pending";

@Component({
  components: {
    VisitDropdown,
  },
  methods: {
    displaySpanRange,
    sessionDate,
  },
  metaInfo: {
    title: i18n.t(`page-title.${pageKey}`).toString(),
    meta: [
      {
        name: "description",
        content: i18n.t(`meta-description.${pageKey}`).toString(),
      },
    ],
  },
})
export default class MyVisitsPending extends Vue {
  pendingSessions: Session[] = [];
  loaded = false;

  @Prop()
  isDashboard!: boolean;

  mounted() {
    this.getSessions();
  }

  async getSessions() {
    try {
      this.getPendingSessions();
    } catch (error) {
      console.log(error);
    }
  }

  async getPendingSessions() {
    const response = await get.sessions();
    this.loaded = true;
    this.pendingSessions = response.data.filter(
      (session) =>
        new Date().getTime() < session.span.start && !session.cancelled
    );
    this.pendingSessions.sort(
      (session1, session2) => session1.span.start - session2.span.start
    );
  }

  get countSessions() {
    let count = 0;
    this.pendingSessions.forEach((session) => {
      if (!session.cancelled) {
        count++;
      }
    });
    return count;
  }

  openModal(session: Session) {
    this.$openModal({
      component: "VisitCancelTherapist",
      closeButton: true,
      props: {
        session: session,
        reloadSessions: this.getSessions,
      },
    });
  }
}
