
import {
    Component,
    Vue,
    Prop
} from "vue-property-decorator";
import {
    displaySpanRange,
} from "@/views/therapistCalendar/helpers";
import {
    Session
} from "@/interfaces/therapy";
import {
    dayNumber,
    dayName,
    monthName,
} from "../../views/dashboard/visits/helpers";

@Component({
    methods: {
        displaySpanRange,
        dayNumber,
        dayName,
        monthName,
    },
})
export default class TherapistBookedVisit extends Vue {
    @Prop()
    session!: Session;
    @Prop()
    color!: string;
    @Prop() // 'patient' or 'therapist'
    type!: string;

    showMoreInfo = false;

    hour(span: number) {
        const formatter = Intl.DateTimeFormat("en", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
        });
        return formatter.format(new Date(span));
    }

    get date(){
        return Date.now();
    }
}
